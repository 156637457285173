import React from "react"
import styled, { css } from "styled-components"
import { theme } from "../../constants/theme"

import BaseBody from "./Body"
import BaseImage, { Props as ImageProps } from "./Image"
import { flexBreakpoint } from "./styles"
import { CommonProps, SectionType } from "./types"

interface ContainerProps {
  imageDirection?: "left" | "right"
  background?: "primary" | "secondary"
  index?: number
}

const getFlexDirection = (
  imageDirection?: ContainerProps["imageDirection"]
): string => {
  switch (imageDirection) {
    case "left":
      return "row"
    case "right":
      return "row-reverse"
    default:
      return "column"
  }
}

const getStylesFromProps = ({
  imageDirection,
  background: backgroundType,
}: ContainerProps) => {
  const flexDirection: string = getFlexDirection(imageDirection)
  const background: string =
    backgroundType === "secondary" ? theme.colors.secondary : "#fff"
  return css`
    flex-direction: ${flexDirection};
    background: ${background};
  `
}

export const SectionWithMargin = styled.section``

const BaseContainer = styled(SectionWithMargin)<ContainerProps>`
  ${getStylesFromProps}
  ${flexBreakpoint`
    display: flex;
    align-items: center;
  `}
`

export interface Props extends ContainerProps, CommonProps, SectionType {
  Image?: React.FC<ImageProps>
  Container?: typeof BaseContainer
  Body?: typeof BaseBody
  id?: string
}

export default function Section({
  image,
  imageDirection,
  background,
  Image = BaseImage,
  Container = BaseContainer,
  Body = BaseBody,
  id,
  ...other
}: Props) {
  return (
    <Container imageDirection={imageDirection} background={background} id={id}>
      {image && <Image image={image} />}
      <Body {...other} />
    </Container>
  )
}

export { BaseContainer as Container }
