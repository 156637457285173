import React from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../../constants/breakpoints"

import Feature from "../../../../types/api/Feature"
import { CommonProps } from "../../types"
import BaseIconsItem from "./IconItem"
import { iconFlexBreakpoint } from "./styles"

interface ListProps extends CommonProps {}

const getStylesFromProps = ({ iconsOnXl }: ListProps) => {
  const justifyContentXl: string =
    iconsOnXl === 3 ? "flex-start" : "space-between"
  return css`
    ${breakpoints("xl")`
    justify-content: ${justifyContentXl};
  `}
  `
}

export const BaseList = styled.ul<ListProps>`
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;

  ${iconFlexBreakpoint`
    margin-top: 3rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  `}

  ${getStylesFromProps}
`

export interface Props extends ListProps {
  features: Feature[]
  List?: typeof BaseList
  IconItem?: typeof BaseIconsItem
}

export default function IconsList({
  List = BaseList,
  features,
  iconsOnXl = 2,
  IconItem = BaseIconsItem,
}: Props) {
  if (!features.length) return null

  const renderFeature = (feature: Feature, index: number): JSX.Element => {
    return (
      <IconItem
        {...feature}
        iconsOnXl={iconsOnXl}
        index={index}
        icons={features.length}
        key={index}
      />
    )
  }

  const renderedFeatures: JSX.Element[] = features.map(renderFeature)

  return <List iconsOnXl={iconsOnXl}>{renderedFeatures}</List>
}
