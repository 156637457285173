import React from "react"
import styled from "styled-components"

import { SubSubHeading } from "../../styles"

interface Props {
  title: string
  value: string
}

const Text = styled.p`
  text-overflow: ellipsis;
`

export default function Section({ title, value }: Props) {
  return (
    <>
      <SubSubHeading>{title}</SubSubHeading>
      <Text>{value}</Text>
    </>
  )
}
