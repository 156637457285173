import React from "react"
import styled from "styled-components"

import Author from "../../../types/api/Author"
import CardBody from "../../CardBody"
import Side from "../Side"
import Section from "./Section"
import Thumbnail from "../Thumbnail"
import { PrimaryGatsbyLink } from "../../PrimaryLink"

interface Props {
  job: Author["job"]
  email: Author["email"]
  slug: Author["slug"]
  secondaryAvatar: Author["secondaryAvatar"]
  profile: Author["profile"]
}

const StyledLink = styled(PrimaryGatsbyLink)`
  display: block;
  margin-top: 2rem;
`

export default function Back({
  job,
  email,
  slug,
  secondaryAvatar,
  profile,
}: Props) {
  const href: string = `/team/${slug}`
  return (
    <Side isBackside>
      <Thumbnail imageData={secondaryAvatar} />
      <CardBody>
        {job && <Section title="Job" value={job} />}
        {email && <Section title="Email" value={email} />}
        {profile && <StyledLink to={href}>View Profile</StyledLink>}
      </CardBody>
    </Side>
  )
}
