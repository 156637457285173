import styled from "styled-components"

interface Props {
  isBackside: boolean
}

const Side = styled.div<Props>`
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: ${(props: Props) =>
    props.isBackside ? "rotateY(180deg)" : "none"};
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default Side
