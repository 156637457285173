import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import QueryWithNodes from "../types/api/QueryWithNodes"
import Author from "../types/api/Author"
import ContentArea from "./ContentArea"
import { Heading } from "./styles"
import FlexList from "./FlexList"
import Person from "./Person"
import { SectionWithMargin } from "./Section"

interface Data {
  allStrapiAuthor: QueryWithNodes<Author>
}

export default function Team() {
  const {
    allStrapiAuthor: { nodes: authors },
  }: Data = useStaticQuery(graphql`
    {
      allStrapiAuthor(sort: { order: ASC, fields: order }) {
        nodes {
          ...Author
        }
      }
    }
  `)

  const renderItem = (person: Author): JSX.Element => {
    return <Person {...person} key={person.slug} />
  }

  const renderedItems: JSX.Element[] = authors.map(renderItem)

  return (
    <SectionWithMargin>
      <ContentArea alternate>
        <Heading center style={{ padding: `5rem` }}>
          Our Team
        </Heading>
        <FlexList style={{ margin: `1rem`, justifyContent: "center" }}>
          {renderedItems}
        </FlexList>
      </ContentArea>
    </SectionWithMargin>
  )
}
