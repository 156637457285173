import React from "react"

import SectionComponentType from "../../../types/api/Section"
import RichText from "../../RichText"

interface Props {
  content: SectionComponentType["content"]
}

export default function Content({ content }: Props) {
  return <RichText text={content} />
}
