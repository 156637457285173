import React from "react"
import styled from "styled-components"

import { keys } from "../../../../../config/keys"
import { breakpoints } from "../../../../../constants/breakpoints"
import { Icon } from "../../../../../types/api/Feature"
import { getSize } from "../../../../../utils/styles"
import Hexagon from "./Hexagon"
import { iconFlexBreakpoint } from "../styles"

interface Props extends Icon {}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  ${getSize("5rem")}

  ${iconFlexBreakpoint`
    ${getSize("2.25rem")}
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 0.625rem;
  `}
`

const IconImg = styled.img`
  width: 1.625rem;
  z-index: 1;

  ${breakpoints("md")`
    width: 0.8125rem;
  `}
`

export default function IconWrapper({ url }: Props) {
  // Because the URL is the AWS url in production, but a relative URL in development
  const fullUrl: string =
    process.env.NODE_ENV === "production" ? url : `${keys.strapiUrl}${url}`
  return (
    <Container>
      <Hexagon />
      <IconImg src={fullUrl} />
    </Container>
  )
}
