import styled from "styled-components"

import { styles as baseStyles } from "./PrimaryButton"
import Link, { StyledGatsbyLink } from "./Link"

const PrimaryLink = styled(Link)`
  ${baseStyles}
`

export const PrimaryGatsbyLink = styled(StyledGatsbyLink)`
  ${baseStyles}
`

export default PrimaryLink
