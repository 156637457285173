import React from "react"
import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

import Feature from "../../../../../types/api/Feature"
import { breakpoints } from "../../../../../constants/breakpoints"
import IconWrapper from "./IconWrapper"
import { flexBreakpoint } from "../../../styles"
import { CommonProps } from "../../../types"
import { getFlexWidth } from "../../../../../utils/styles"

interface ContainerProps extends CommonProps {
  index: number
  icons: number
}

type ChangeColumnsBreakpoint = "md" | "xl"

const getMarginBottom = (
  breakpoint: ChangeColumnsBreakpoint,
  { index, icons, iconsOnXl }: ContainerProps,
  margin: string = "1rem"
): string | number => {
  const columns: number = breakpoint === "xl" ? iconsOnXl : 2
  const columnsOnLastRow: number = icons % columns
  const lastIndex: number = icons - columnsOnLastRow
  const isOnLastRow: boolean = index >= lastIndex
  return isOnLastRow ? 0 : margin
}

const leftMarginsByColumn = {
  2: {
    md: "0.625rem",
    lg: "3.3125rem",
  },
}

const getXlWidth = ({ iconsOnXl }: ContainerProps): string => {
  if (iconsOnXl === 3) {
    return "9.9375rem"
  }
  return getFlexWidth(2, leftMarginsByColumn["2"].lg)
}

const getStylesFromProps = (props: ContainerProps) => {
  const widthMd = getFlexWidth(2, leftMarginsByColumn["2"].md)
  const marginLeftXl: string | number = props.iconsOnXl === 3 ? "2rem" : 0
  return css`
    ${flexBreakpoint`
      margin-bottom: ${getMarginBottom("md", props)};
      width: ${widthMd};
      flex-basis: ${widthMd};
    `}

    ${breakpoints("xl")`
      margin-bottom: ${getMarginBottom("xl", props, "1.75rem")};
      flex-basis: auto;
      width: ${getXlWidth(props)};

      & + & {
        margin-left: ${marginLeftXl};
      }
    `}
  `
}

export const BaseListItem = styled.li<ContainerProps>`
  list-style: none;
  text-align: center;
  ${getStylesFromProps}

  & + & {
    margin-top: 2rem;

    ${flexBreakpoint`
      margin-top: 0;
    `}
  }
`

const Text = styled.p`
  ${breakpoints("md")`
    font-size: 0.875rem;
    line-height: 1.5rem;
  `}
`

const flexStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${flexBreakpoint`
    text-align: left;
    flex-direction: row;
  `}
`

const DefaultContainer = styled.div`
  ${flexStyles}
`

const Link = styled(GatsbyLink)`
  text-decoration: none;
  ${flexStyles}

  &:hover {
    text-decoration: underline;
  }
`

export interface Props extends ContainerProps, Feature {
  ListItem?: typeof BaseListItem
}

export default function IconItem({
  iconsOnXl,
  index,
  icons,
  icon,
  title,
  url,
  ListItem = BaseListItem,
}: Props) {
  const renderChildren = (): React.ReactNode => (
    <>
      <IconWrapper {...icon} />
      <Text>{title}</Text>
    </>
  )

  const renderContent = (): React.ReactNode => {
    const children: React.ReactNode = renderChildren()
    if (url) {
      return <Link to={url}>{children}</Link>
    }
    return <DefaultContainer>{children}</DefaultContainer>
  }

  const content: React.ReactNode = renderContent()

  return (
    <ListItem iconsOnXl={iconsOnXl} index={index} icons={icons}>
      {content}
    </ListItem>
  )
}
