import React from "react"
import styled from "styled-components"

import Thumbnail, { Props as ThumbnailProps } from "../Thumbnail"
import breakpoints from "../../constants/breakpoints"

const StyledThumbnail = styled(Thumbnail)`
  height: 13.625rem;

  ${breakpoints("md")`
    height: 15rem;
  `}

  ${breakpoints("lg")`
    height: 17rem;
  `}

  & img {
    will-change: unset !important;
    transform: none !important;
    transition: none !important;
  }
`

interface Props extends ThumbnailProps {}

const ThumbnailWrapper = (props: Props) => {
  return <StyledThumbnail objectFit="cover" objectPosition="top" {...props} />
}

export default ThumbnailWrapper
