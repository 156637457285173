import React from "react"
import styled, { css } from "styled-components"

import { breakpoints } from "../../constants/breakpoints"
import SectionComponentType from "../../types/api/Section"
import Thumbnail, { StyledImage as BaseStyledImage } from "../Thumbnail"
import { flexBreakpoint } from "./styles"

const maxHeightStyles = css`
  max-height: 37.5rem;
`

export const BaseContainer = styled.div`
  ${maxHeightStyles}
  max-width: 100%;
  flex-shrink: 0;

  ${flexBreakpoint`
    width: 33%;
    align-self: stretch;
  `}

  ${breakpoints("lg")`
    width: 50%;
  `}
`

export const StyledImage = styled(BaseStyledImage)`
  ${maxHeightStyles}
  height: 100%;
`

export interface Props {
  image: SectionComponentType["image"]
  ImageComponent?: typeof StyledImage
  Container?: typeof BaseContainer
}

export default function Image({
  image,
  ImageComponent = StyledImage,
  Container = BaseContainer,
}: Props) {
  return (
    <Container>
      <Thumbnail
        Component={ImageComponent}
        imageData={image}
        objectFit="cover"
      />
    </Container>
  )
}
