export const getSize = (amount: string): string => `
  width: ${amount};
  height: ${amount};
`

export const getPaddingY = (amount: string): string => `
  padding-top: ${amount};
  padding-bottom: ${amount};
`

export const getPaddingX = (amount: string): string => `
  padding-left: ${amount};
  padding-right: ${amount};
`

export const getFlexWidth = (columns: number, margin: string): string => {
  return `calc(100% / ${columns} - ${margin} / 2)`
}

export const getMarginY = (amount: string): string => `
  margin-top: ${amount};
  margin-bottom: ${amount};
`

export const getMarginX = (amount: string): string => `
  margin-left: ${amount};
  margin-right: ${amount};
`
