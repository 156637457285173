import * as React from "react"
import styled from "styled-components"

const Container = styled.svg`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`

function Hexagon(props: Omit<React.SVGProps<SVGSVGElement>, "ref">) {
  return (
    <Container
      viewBox="0 0 36 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.993 18.5a4 4 0 010-4L7.765 2.771a4 4 0 013.464-2h13.543a4 4 0 013.464 2L35.007 14.5a4 4 0 010 4l-6.771 11.729a4 4 0 01-3.464 2H11.229a4 4 0 01-3.464-2L.993 18.5z"
        fill="#353535"
      />
    </Container>
  )
}

export default Hexagon
