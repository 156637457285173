import React from "react"

import Side from "./Side"
import CardInfo from "../CardInfo"
import Author from "../../types/api/Author"
import ThumbnailWrapper from "./Thumbnail"

interface Props {
  name: Author["name"]
  job: Author["job"]
  description: Author["description"]
  mainAvatar: Author["mainAvatar"]
}

export default function Front({ name, job, description, mainAvatar }: Props) {
  return (
    <Side isBackside={false}>
      <ThumbnailWrapper imageData={mainAvatar} />
      <CardInfo name={name} title={job} description={description} />
    </Side>
  )
}
