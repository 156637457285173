import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Front from "./Front"
import Back from "./Back/index"
import Author from "../../types/api/Author"
import "../../../node_modules/animate.css/animate.min.css"
import { ShouldShowBack } from "./types"

const cardWidthFinal: string = "29.3125rem"
const cardWidthLg: string = "20.625rem"

const Container = styled.div`
  position: relative;
  padding: 0;
  margin: 0 0 2rem;
  box-sizing: border-box;
  width: ${cardWidthLg};
  flex-basis: ${cardWidthLg};
  height: 35rem;

  @media (min-width: 1440px) {
    width: ${cardWidthFinal};
    flex-basis: ${cardWidthFinal};
    height: 37.3125rem;
  }

  @media (max-width: 768px) {
    width: 21rem;
    flex-basis: 22rem;
  }

  @media (max-width: 640px) {
    width: 22.5625rem;
    flex-basis: 23rem;
  }

  @media (max-width: 424px) {
    width: 16rem;
    flex-basis: 18rem;
  }
`

const InnerContainer = styled.div`
  margin: 0 1rem;
  height: 100%;
`

const Button = styled.button`
  border: 0;
  perspective: 100rem;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: left;

  &,
  &:hover {
    background-color: transparent;
  }
`

interface WrapperProps {
  rotate: boolean
}

// From https://1stwebdesigner.com/learn-how-to-create-flip-cards-using-css/
const Wrapper = styled.div<WrapperProps>`
  transition-property: transform;
  transition-duration: 0.75s;
  transform-style: preserve-3d;
  height: 100%;
  transform: ${(props: WrapperProps) =>
    props.rotate ? "rotateY(180deg)" : "none"};
`

interface Props extends Author {}

const Person = ({
  name,
  job,
  description,
  mainAvatar,
  slug,
  ...other
}: Props) => {
  const [shouldShowBack, setShouldShowBack] = useState<ShouldShowBack>(false)
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const [flipOnHover, setFlipOnHover] = useState<boolean>(true)
  const [lastFlipToBack, setLastFlipToBack] = useState<Date | null>(null)
  const [didHover, setDidHover] = useState<boolean>(false)

  const hideBack = () => setShouldShowBack(false)

  const updateLastFlip = (): void => {
    const date = new Date()
    setLastFlipToBack(date)
  }

  const tryHidingCard = (): void => {
    if (!didHover) return
    if (!isHovering) {
      hideBack()
    }
    if (!isHovering && !flipOnHover) {
      setFlipOnHover(true)
      setLastFlipToBack(null)
      setDidHover(false)
    }
  }

  const showBack = (): void => {
    setShouldShowBack(true)
    updateLastFlip()
  }

  const handleMouseOver = (): void => {
    setIsHovering(true)
    setDidHover(true)
    if (!shouldShowBack && flipOnHover) {
      showBack()
    }
  }

  const handleMouseLeave = (): void => {
    setIsHovering(false)
  }

  useEffect(() => {
    // To prevent card flickering when hovering on card edge
    tryHidingCard()
  }, [isHovering, flipOnHover, didHover])

  const toggleShouldShowBack = () => {
    if (!shouldShowBack) {
      updateLastFlip()
    }
    setShouldShowBack(!shouldShowBack)
  }

  const flipCardUnlessJustFlipped = (): void => {
    // In case they did not hover on the card
    if (!lastFlipToBack) return toggleShouldShowBack()
    const currentDate = new Date()
    const timeSinceLastFlip = currentDate.valueOf() - lastFlipToBack.valueOf()
    if (timeSinceLastFlip > 250) {
      toggleShouldShowBack()
    }
  }

  const handleClick = (): void => {
    setFlipOnHover(false)
    // To prevent it from updating the first time because a hover event would have already shown the back
    flipCardUnlessJustFlipped()
  }

  return (
    <Container>
      <InnerContainer>
        <Button
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          <Wrapper rotate={shouldShowBack}>
            <Back job={job} slug={slug} {...other} />
            <Front
              name={name}
              job={job}
              description={description}
              mainAvatar={mainAvatar}
            />
          </Wrapper>
        </Button>
      </InnerContainer>
    </Container>
  )
}

export default Person
