import React from "react"
import styled from "styled-components"

import BaseTitle from "./Title"
import Content from "./Content"
import BaseFeatures from "./IconsList"
import { flexBreakpoint } from "../styles"
import { CommonProps, SectionType } from "../types"
import { getPaddingX, getPaddingY } from "../../../utils/styles"
import { theme } from "../../../constants/theme"

export interface TitleProps {
  children: React.ReactNode
}

interface ContainerProps {
  children: React.ReactNode
}

export interface Props extends Omit<SectionType, "image">, CommonProps {
  children?: React.ReactNode
  Title?: React.FC<TitleProps>
  Container?: React.FC<ContainerProps>
  Features?: typeof BaseFeatures
  beforeTitle?: React.ReactNode
}

export const BaseContainer = styled.div`
  ${getPaddingY("3rem")}
  ${getPaddingX(theme.pagePaddingX)}

  ${flexBreakpoint`
    padding: 3rem;
  `}
`

export default function Body({
  content,
  title,
  features,
  iconsOnXl,
  children = null,
  Title = BaseTitle,
  beforeTitle = null,
  Container = BaseContainer,
  Features = BaseFeatures,
}: Props) {
  return (
    <Container>
      {beforeTitle}
      <Title>{title}</Title>
      {content && <Content content={content} />}
      {features && <Features features={features} iconsOnXl={iconsOnXl} />}
      {children}
    </Container>
  )
}
