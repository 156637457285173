import styled from "styled-components"
import { Heading } from "../../styles"

const Title = styled(Heading)`
  margin-bottom: 1.5rem;
  line-height: 2rem;

  &::after {
    padding-top: 0.625rem;
  }
`

export default Title
